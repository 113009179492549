import React, { useContext, useEffect, useState } from 'react'
import { GEOCODDING_LOCATION_API_KEY, SEARCHNEWS_URL, WEBSOCKET_BASE_URL } from '../services/Constants';
import { Button, Card, Col, Container, Row, Stack, Form } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export default function EventsNearMe() {

    let {
        notifyCustomSuccessMessage,
        notifyCustomErrorMessage
    } = useContext(AuthContext)

    const navigate = useNavigate();

    const [eventlocation, setEventLocation] = useState('');
    const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
    const [locationError, setLocationError] = useState(null);
    const [loadingEventGeoLocation, setLoadingEventGeoLocation] = useState(false);
    const [range, setRange] = useState(5);

    // Get current location device location

    const getUserAddress = async (lat, lng) => {
        let url = `https://api.opencagedata.com/geocode/v1/json?key=${GEOCODDING_LOCATION_API_KEY}&q=${lat}%2C+${lng}&pretty=1&no_annotations=1`;
        const loc = await fetch(url)
        const data = await loc.json()
        console.log("User Address", data);
        setEventLocation(data.results[0].formatted);
    }

    const getCoordinates = async () => {
        if (navigator.geolocation) {
            setLoadingEventGeoLocation(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCoordinates({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setLocationError('');
                    getUserAddress(position.coords.latitude, position.coords.longitude);
                    setLoadingEventGeoLocation(false);
                },
                (err) => {
                    setLocationError(err.message);
                    setLoadingEventGeoLocation(false);
                }
            );
        } else {
            setLocationError('Geolocation is not supported by your browser.');
        }
    };

    const searchEventsLocation = async () => {
        const baseUrl = `${SEARCHNEWS_URL}`;
        console.log('range:', range);
        console.log('latitude:', coordinates.latitude);
        console.log('longitude:', coordinates.longitude);
        const latitudeMax = coordinates.latitude + Number(range);
        console.log('latitudeMax:', latitudeMax);
        const latitudeMin = coordinates.latitude - range;
        console.log('latitudeMin:', latitudeMin);
        const longitudeMax = coordinates.longitude + Number(range);
        console.log('longitudeMax:', longitudeMax);
        const longitudeMin = coordinates.longitude - range;
        console.log('longitudeMin:', longitudeMin);
        const apiEndPointUrl = baseUrl + 'min_range_latitude=' + latitudeMin + '&max_range_latitude=' + latitudeMax + '&min_range_longitude=' + longitudeMin + '&max_range_longitude=' + longitudeMax;
        let response = fetch(`${apiEndPointUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        console.log('response:', response)
        const apiData = (await response).json()
        console.log('data:', apiData)
        const status = (await response).status
        if (status === 200) {
            const searchInfo = (await apiData)
            console.log('searchInfo: ', searchInfo)
            notifyCustomSuccessMessage("Data found successfully.")
        } else {
            notifyCustomErrorMessage("No data found.")
        }
    }

    // useEffect(() => {
    //     // const socket = new WebSocket('ws://127.0.0.1:8000/ws/gps/');
    //     const socket = new WebSocket(`${WEBSOCKET_BASE_URL}/ws/gps/`);

    //     const sendLocation = () => {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             const { latitude, longitude } = position.coords;
    //             socket.send(JSON.stringify({ location: { lat: latitude, lng: longitude } }));
    //         });
    //     };

    //     // Send location at intervals
    //     const interval = setInterval(sendLocation, 5000);

    //     return () => {
    //         clearInterval(interval);
    //         socket.close();
    //     };
    // }, []);

    return (
        <>
            <Container>
                <div className='d-flex justify-content-start my-3'>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ fontSize: 14, fontWeight: 600, }}
                        onClick={() => navigate('/')}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Back
                    </button>
                </div>
                <Card className='mt-3'>
                    <Card.Body>
                        {/* <Form> */}
                        <Row>
                            <Form.Label>Location
                                <span className='text-danger'> * </span>
                            </Form.Label>
                            <Stack direction="horizontal" gap={3}>
                                <Form.Group as={Col} sm="9">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter the location"
                                        value={eventlocation}
                                        onChange={(e) => setEventLocation(e.target.value)}
                                        disabled
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a specific location.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" onClick={getCoordinates}>Get Current Location</Button>
                                <Form.Group as={Col}>
                                    <Form.Select
                                        // size="sm"
                                        value={range}
                                        onChange={(e) => setRange(e.target.value)}
                                        required
                                    >
                                        <option value="5" selected>5 km</option>
                                        <option value="10">10 km</option>
                                        <option value="15">15 km</option>
                                    </Form.Select>
                                </Form.Group>
                            </Stack>
                            <div>
                                {locationError && <p style={{ color: "red" }}>{locationError}</p>}
                                {loadingEventGeoLocation && <p>Loading.Please wait...</p>}
                            </div>
                            <div className='mt-3 d-flex justify-content-center'>
                                <Button variant="primary" onClick={searchEventsLocation}>Search Events</Button>
                            </div>
                        </Row>
                        {/* </Form> */}
                    </Card.Body>
                </Card>
            </Container>
            <ToastContainer />
        </>
    )
}
