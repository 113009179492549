import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../components/Navbar';
// import './Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ADDADDRESS_URL, ADDRESS_URL, CHANGESPORTPROFILE, SOCIALMEDIALINKS_URL, SPORTPROFILETYPECHANGEUSERSTATUS_URL, SPORTPROFILE_URL, USER_URL, optionsCountry, optionsLanguageSpoken } from '../services/Constants';
import AuthContext from '../context/AuthContext'
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Alert } from 'react-bootstrap';
// import '../components/css/SideNetworkBar.css';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from '../utils/Firebase';
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleChangePresentAddressSubmit, handleSetPresentAddressSubmit } from '../services/ApiServices';
import { ProfileBuilderNavBar } from '../components/ProfileBuilderNavBar';
import Select from 'react-select';

export default function EditProfile() {

    let {
        user,
        authTokens,
        notifyRegistrationPersonalInfo,
        notifyCustomSuccessMessage,
        notifyError,
        notifyAuthenticateError,
        notifyCustomWarningMessage
    } = useContext(AuthContext)

    const navigate = useNavigate();

    // const { isFlag } = useParams();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param = params.get('isFlag');

    // console.log('isFlag:', param);

    const [key, setKey] = useState('selectprofiles');

    // State variable of search user profile in Navbar

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [flag, setFlag] = useState(param === undefined ? true : !param ? true : false);

    // State variable to manage form validation

    const [validatedPersonalInfo, setValidatedPersonalInfo] = useState(false);
    const [validatedAboutMe, setValidatedAboutMe] = useState(false);
    const [validatedAddresses, setValidatedAddresses] = useState(false);
    const [validatedSocialMediaLinks, setValidatedSocialMediaLinks] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [addressStatus, setAddressStatus] = useState(false);

    // State variable of User Personal Information

    const [userPersonalData, setUserPersonalData] = useState('')
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [sportType, setSportType] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [citizenship, setCitizenship] = useState([]);
    const [languageSpoken, setLanguageSpoken] = useState([]);
    const [sportTypeError, setSportTypeError] = useState("");
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [dob, setDob] = useState("");
    // const [dobError, setDobError] = useState("");
    const [dobAgeError, setDobAgeError] = useState("");
    const [contactNo, setContactNo] = useState("");
    // const [contactNoError, setContactNoError] = useState("");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState('');

    // State variable of User address

    const [permanentAddress, setPermanentAddress] = useState('');
    const [pmAddressId, setPmAddressId] = useState(null);

    // State variable of present address

    const [isAddressChanged, setIsAddressChanged] = useState(false);
    const [isPmAddress, setIsPmAddress] = useState(false);
    const [presentAddress, setPresentAddress] = useState([]);
    const [addressId, setAddressId] = useState(null);
    const [addressLane, setAddressLane] = useState('');
    const [addressLaneError, setAddressLaneError] = useState('');
    const [landmark, setLandmark] = useState('');
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');
    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState('');
    const [state, setState] = useState('');
    const [stateError, setStateError] = useState('');
    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState('');
    const [addressType, setAddressType] = useState('');
    const [addressTypeError, setAddressTypeError] = useState('');

    // State variable to manage profile description

    const [profileDescription, setProfileDescription] = useState('')
    const [profileDescriptionError, setProfileDescriptionError] = useState('')

    // State variable used for managing the social media links

    const [socialMediaPlatform, setSocialMediaPlatform] = useState('');
    const [socialMediaLink, setSocialMediaLink] = useState('');
    const [socialMediaTitle, setSocialMediaTitle] = useState('');
    const [socialMediaInfo, setSocialMediaInfo] = useState([]);
    const [socialMediaId, setSocialMediaId] = useState(null);

    // State variable to manage sport profile type

    const [sportProfile, setSportProfile] = useState([])
    const [selectedPlayerStatusOption, setSelectedPlayerStatusOption] = useState({});
    const [selectedCoachStatusOption, setSelectedCoachStatusOption] = useState('');
    const [selectedAgentStatusOption, setSelectedAgentStatusOption] = useState('');

    const [selectedPlayerSwitchOption, setSelectedPlayerSwitchOption] = useState(false);
    const [selectedCoachSwitchOption, setSelectedCoachSwitchOption] = useState(false);
    const [selectedAgentSwitchOption, setSelectedAgentSwitchOption] = useState(false);

    // Manage Model

    const [showEditPersonalModal, setShowEditPersonalModal] = useState(false);
    const [showEditProfileDescriptionModal, setShowEditProfileDescriptionModal] = useState(false);
    const [showCreateSocialMediaLinksModal, setShowCreateSocialMediaLinksModal] = useState(false);
    const [showEditSocialMediaLinksModal, setShowEditSocialMediaLinksModal] = useState(false);
    const [showCreateAddressModal, setShowCreateAddressModal] = useState(false);
    const [showEditAddressModal, setShowEditAddressModal] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // function responsible for handling navigation with params

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    // Get User Info function

    const getUserPersonalInfo = async () => {
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json();
        // console.log('data:', data)
        const userData = (await data);
        // console.log('User info:', userData)
        const status = (await response).status;
        if (status === 200) {
            setFirstName(userData.first_name);
            setLastName(userData.last_name);
            setDob(userData.dob);
            setHeight(userData.height);
            setWeight(userData.weight);
            setSportType(userData.sport_type);
            setProfileDescription(userData.bio);
            setContactNo(userData.contact_no);
            setCountryCode(userData.country_code);
            if (userData.citizenship !== null) {
                const newArray = userData.citizenship.map(item => ({
                    label: item.name,
                    value: item.name
                }));
                // console.log('newArray: ', newArray);
                setCitizenship(newArray)
            } else {
                setCitizenship([])
            }
            if (userData.languages_spoken !== null) {
                const newArray = userData.languages_spoken.map(item => ({
                    label: item.name,
                    value: item.name
                }));
                // console.log('newArray: ', newArray);
                setLanguageSpoken(newArray)
            } else {
                setLanguageSpoken([])
            }
        } else {
            notifyError();
        }
    }

    const getSocialMediaInfo = async (id) => {
        let response = fetch(`${SOCIALMEDIALINKS_URL}${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json();
        const social = (await data);
        console.log('social:', social)
        const status = (await response).status;
        if (status === 200) {
            setSocialMediaId(id)
            setSocialMediaPlatform(social.platform);
            setSocialMediaLink(social.link);
            setSocialMediaTitle(social.title);
        } else {
            notifyError();
        }
    }

    const getAddressInfo = async (id) => {
        setAddressId(id)
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const address = (await data)
        // console.log('address:', address)
        const status = (await response).status
        if (status === 200) {
            setAddressLane(address.address_lane)
            setLandmark(address.landmark)
            setCity(address.city)
            setState(address.state)
            setCountry(address.country)
            setPin(address.pin)
            setAddressType(address.address_type)
        } else {
            notifyError();
        }
    }

    const getPresentAddressInfo = async (id) => {
        setAddressId(id)
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const address = (await data)
        // console.log('address:', address)
        const status = (await response).status
        if (status === 200) {
            setAddressLane(address.address_lane)
            setLandmark(address.landmark)
            setCity(address.city)
            setState(address.state)
            setCountry(address.country)
            setPin(address.pin)
            setAddressType(address.address_type)
        } else {
            notifyError();
        }
    }

    // handle add User data function

    const handleSubmit = async () => {
        // event.preventDefault();
        // setIsLoading(true);
        setValidatedPersonalInfo(true);
        const formData = new FormData();
        if (firstName !== '') {
            formData.append("first_name", firstName);
            setFirstNameError('');
        } else {
            setFirstNameError('First name cannot be empty');
            return;
        }
        if (lastName !== '') {
            formData.append("last_name", lastName);
            setLastNameError('');
        } else {
            setLastNameError('Last name cannot be empty');
            return;
        }
        // console.log('sport type: ', sportType);
        if (sportType !== null) {
            formData.append("sport_type", sportType);
            setSportTypeError('');
        } else {
            setSportTypeError('Sport type cannot be empty');
            return;
        }
        if (height !== null) {
            // console.log('height', height);
            formData.append("height", height);
        } else {
            formData.append("height", 0);
        }
        if (weight !== null) {
            // console.log('weight', weight);
            formData.append("weight", weight);
        } else {
            formData.append("weight", 0);
        }
        if (dob !== null) {
            // console.log('dob', dob);
            const mydob = JSON.stringify(dob)
            const splitDob = mydob.split("-")
            // console.log('year: ', splitDob[0])
            // console.log('month: ', splitDob[1])
            // console.log('day: ', splitDob[2])
            const yr = splitDob[0].slice(1);
            // console.log('year: ', yr)
            // const dy = splitDob[2].slice(0, -1);
            // console.log('day: ', dy)
            const currentYear = new Date().getFullYear();
            // console.log('current year:', currentYear);
            const age = currentYear - yr
            // console.log('my age:', age);
            if (age < 6) {
                setDobAgeError('Date of birth cannot be less than 6 years');
                setValidatedPersonalInfo(false);
                return;
            } else {
                formData.append("dob", dob);
                // setDobError('');
                setDobAgeError('');
            }
        }
        formData.append("contact_no", contactNo);
        if (contactNo !== '' && contactNo !== 'null') {
            console.log('contact no.: ', contactNo);
            if (countryCode === '' && countryCode === null) {
                console.log('country code: ', countryCode);
                setCountryCodeError('Country Code cannot be empty');
                setValidatedPersonalInfo(false);
                return;
            } else if (countryCode === 'null') {
                console.log('country code: ', countryCode);
                setCountryCodeError('Country Code cannot be empty');
                setValidatedPersonalInfo(false);
                return;
            } else {
                // console.log('country code: ', countryCode);
                formData.append("country_code", countryCode);
                setCountryCodeError('');
            }
            // formData.append("contact_no", contactNo);
            // setContactNoError('');
        }
        // let downloadStorageURL = '';
        if (selectedFile !== null) {
            formData.append("profile_photo", selectedFile);

            // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: 'image/jpeg'
            };

            // Upload file and metadata to the object 'images/mountains.jpg'
            const storageRef = ref(storage, 'images/' + selectedFile.name);
            const uploadTask = uploadBytesResumable(storageRef, selectedFile, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    notifyError()
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        formData.append("profile_photo_url", downloadURL);
                        setProfilePhotoUrl(downloadURL);
                        // downloadStorageURL = downloadURL;
                        // Log the key/value pairs
                        // for (const pair of formData.entries()) {
                        //   console.log(pair[0] + ': ' + pair[1]);
                        // }
                        // Django Rest Api
                        // let response = fetch(`${USER_URL}/${user.user_id}/`, {
                        //     method: "PATCH",
                        //     headers: {
                        //         // 'Content-Type': 'application/json',
                        //         // 'Content-Type': 'multipart/form-data',
                        //         'Authorization': 'Bearer ' + String(authTokens.access)
                        //     },
                        //     body: formData,
                        // });
                        // console.log('response:', response)
                        // let data = (await response).json()
                        // console.log('data:', data)
                        // let status = (await response).status
                        // console.log('status:', status)
                        // if (status === 200) {
                        // Add a new document with a generated id in collection "users"
                        // const newUserRef = doc(collection(db, "users"));
                        await updateDoc(doc(db, "users", userPersonalData.username), {
                            photoURL: downloadURL
                        });

                        // await setDoc(doc(db, "userChats", userPersonalData.username), {});
                        // notifyRegistrationPersonalInfo()
                        // setFirstName('')
                        // setFirstNameError('')
                        // setLastName('')
                        // setLastNameError('')
                        // setSportType('')
                        // setSportTypeError('')
                        // setHeight('')
                        // setWeight('')
                        // setDob('')
                        // setDobError('')
                        // setContactNo('')
                        // setContactNoError('')
                        // setSelectedFile(null)
                        // setValidatedPersonalInfo(false)
                        // setShowEditPersonalModal(false)
                        // } else {
                        //     notifyError()
                        // }
                    });
                }
            );
            // return;
        }
        // formData.append("profile_photo", selectedFile);
        // formData.append("profile_photo_url", profilePhotoUrl);
        // Log the key/value pairs
        // for (const pair of formData.entries()) {
        //   console.log(pair[0] + ': ' + pair[1]);
        // }
        if (country && country.length > 0) {
            const newArrayCountry = country.map(item => ({
                name: item.value
            }));
            formData.append("citizenship", JSON.stringify(newArrayCountry));
        }
        if (languageSpoken && languageSpoken.length > 0) {
            const newArrayLanguagesSpoken = languageSpoken.map(item => ({
                name: item.value
            }));
            formData.append("languages_spoken", JSON.stringify(newArrayLanguagesSpoken));
        }
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: "PATCH",
            headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            // Add a new document with a generated id in collection "users"
            // const newUserRef = doc(collection(db, "users"));
            await updateDoc(doc(db, "users", userPersonalData.username), {
                name: `${firstName} ${lastName}`
            });
            // await setDoc(doc(db, "userChats", userPersonalData.username), {});
            notifyRegistrationPersonalInfo()
            setFirstName('')
            setFirstNameError('')
            setLastName('')
            setLastNameError('')
            setSportType('')
            setSportTypeError('')
            setHeight('')
            setWeight('')
            setDob('')
            setContactNo('')
            setSelectedFile(null)
            setCitizenship([])
            setLanguageSpoken([])
            setValidatedPersonalInfo(false)
            setShowEditPersonalModal(false)
        } else {
            notifyError()
        }
    }

    const handleSubmitSocialMediaLinks = async () => {
        setValidatedSocialMediaLinks(true);
        const formData = new FormData();
        if (socialMediaPlatform === '') {
            return;
        } else {
            formData.append("platform", socialMediaPlatform);
            if (socialMediaPlatform === 'Others') {
                formData.append("title", socialMediaTitle);
            }
        }
        if (socialMediaLink === '') {
            return;
        } else {
            formData.append("link", socialMediaLink);
        }
        formData.append("user", user.user_id);
        let response = fetch(`${SOCIALMEDIALINKS_URL}`, {
            method: "POST",
            body: formData,
        });
        console.log('response:', response);
        let data = (await response).json();
        console.log('data:', data);
        let status = (await response).status;
        // console.log('status:', status)
        if (status === 201) {
            notifyRegistrationPersonalInfo();
            setSocialMediaLink('');
            setSocialMediaPlatform('');
            setSocialMediaTitle('');
            setValidatedSocialMediaLinks(false);
            setShowCreateSocialMediaLinksModal(false);
        } else {
            notifyError();
        }
    }

    const handleSubmitPresentAddress = async () => {
        // event.preventDefault();
        // setIsLoading(true);
        // setValidatedPresentAddress(true);
        setValidatedAddresses(true);
        const formData = new FormData();
        // if (addressLane === '') {
        //     setAddressLaneError('Address lane cannot be empty')
        //     return;
        // } else {
        //     setAddressLaneError('')
        //     formData.append("address_lane", addressLane);
        // }
        formData.append("address_lane", addressLane);
        if (city === '') {
            setCityError('City cannot be empty')
            return;
        } else {
            setCityError('')
            formData.append("city", city);
        }
        // if (pin === '') {
        //     setPinError('Zip code cannot be empty')
        //     return;
        // } else {
        //     setPinError('')
        //     formData.append("pin", pin);
        // }
        formData.append("pin", pin);
        // if (state === '') {
        //     setStateError('State cannot be empty')
        //     return;
        // } else {
        //     setStateError('')
        //     formData.append("state", state);
        // }
        formData.append("state", state);
        if (country === '') {
            setCountryError('Country cannot be empty')
            return;
        } else {
            setCountryError('')
            formData.append("country", country);
        }
        if (addressType === '') {
            setAddressTypeError('Address type cannot be empty')
            return;
        } else {
            setAddressTypeError('')
            formData.append("address_type", addressType);
        }
        formData.append("landmark", landmark);
        formData.append("present_user_id", [user.user_id]);
        if (isPmAddress) {
            formData.append("permanent_user_id", user.user_id);
        }
        // let response = fetch(`${ADDRESS_URL}/`, {
        let response = fetch(`${ADDADDRESS_URL}`, {
            method: "POST",
            headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        // console.log('status:', status)
        if (status === 201) {
            notifyRegistrationPersonalInfo()
            setAddressLane('')
            setAddressLaneError('')
            setLandmark('')
            setCity('')
            setCityError('')
            setPin('')
            setPinError('')
            setState('')
            setStateError('')
            setCountry('')
            setCountryError('')
            setAddressType('')
            setAddressTypeError('')
            setValidatedAddresses(false)
            setShowCreateAddressModal(false)
            setIsPmAddress(false)
        } else {
            notifyError()
        }
    }

    // handle update function

    const handleUpdateSocialMediaLinks = async () => {
        setValidatedSocialMediaLinks(true);
        const formData = new FormData();
        if (socialMediaLink === '') {
            return;
        } else {
            formData.append("link", socialMediaLink);
            if (socialMediaPlatform === 'Others') {
                formData.append("title", socialMediaTitle);
            }
        }
        if (socialMediaPlatform === '') {
            return;
        } else {
            formData.append("platform", socialMediaPlatform);
        }
        let response = fetch(`${SOCIALMEDIALINKS_URL}${socialMediaId}/`, {
            method: "PATCH",
            body: formData,
        });
        // console.log('response:', response)
        // let data = (await response).json();
        // console.log('data:', data)
        let status = (await response).status;
        // console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo();
            setSocialMediaPlatform('');
            setSocialMediaLink('');
            setSocialMediaId(null);
            setSocialMediaTitle('');
            setValidatedSocialMediaLinks(false);
            setShowEditSocialMediaLinksModal(false);
        } else {
            notifyError();
        }
    }

    const handleUpdateAddress = async () => {
        // event.preventDefault();
        // setIsLoading(true);
        // setValidatedPermanentAddress(true);
        setValidatedAddresses(true);
        const formData = new FormData();
        if (addressLane === '') {
            setAddressLaneError('Address Lane cannot be empty')
            return;
        } else {
            setAddressLaneError('')
            formData.append("address_lane", addressLane);
        }
        if (city === '') {
            setCityError('City cannot be empty')
            return;
        } else {
            setCityError('')
            formData.append("city", city);
        }
        if (pin === '') {
            setPinError('Zip code cannot be empty')
            return;
        } else {
            setPinError('')
            formData.append("pin", pin);
        }
        if (state === '') {
            setStateError('State cannot be empty')
            return;
        } else {
            setStateError('')
            formData.append("state", state);
        }
        if (country === '') {
            setCountryError('Country cannot be empty')
            return;
        } else {
            setCountryError('')
            formData.append("country", country);
        }
        if (addressType === '') {
            setAddressTypeError('Address type cannot be empty')
            return;
        } else {
            setAddressTypeError('')
            formData.append("address_type", addressType);
        }
        formData.append("landmark", landmark);
        let response = fetch(`${ADDRESS_URL}/${addressId}/`, {
            method: "PATCH",
            body: formData,
        });
        // console.log('response:', response)
        let data = (await response).json()
        // console.log('data:', data)
        let status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setAddressLane('')
            setAddressLaneError('')
            setLandmark('')
            setCity('')
            setCityError('')
            setPin('')
            setPinError('')
            setState('')
            setStateError('')
            setCountry('')
            setCountryError('')
            setAddressType('')
            setAddressTypeError('')
            setValidatedAddresses(false)
            setShowEditAddressModal(false)
        } else {
            notifyError()
        }
    }

    const handleUpdatePermanentAddress = async (id) => {
        // event.preventDefault();
        // setIsLoading(true);
        // setValidatedPermanentAddress(true);
        // setValidatedAddresses(true);
        const formData = new FormData();
        formData.append("permanent_user_id", '');
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: "PATCH",
            body: formData,
        });
        // console.log('response:', response)
        // let data = (await response).json()
        // console.log('data:', data)
        let status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
            notifyCustomSuccessMessage('Address successfully deleted');
            // setIsAddressChanged(true);
            setPmAddressId(null);
            setPermanentAddress('');
        } else {
            notifyError()
        }
    }

    const handleUpdateProfileDescription = async (event) => {
        event.preventDefault();
        setValidatedAboutMe(true)
        // setIsLoading(true)
        const formData = new FormData();
        if (profileDescription === '') {
            setProfileDescriptionError('Profile description cannot be empty')
            return;
        } else {
            setProfileDescriptionError('')
            // console.log('profileDescription', profileDescription);
            formData.append("bio", profileDescription);
        }
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: "PATCH",
            headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: formData,
        });
        // console.log('response:', response)
        // let data = (await response).json()
        // console.log('data:', data)
        let status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setProfileDescription('')
            setProfileDescriptionError('')
            // setIsLoading(false)
            setValidatedAboutMe(false)
            setShowEditProfileDescriptionModal(false)
        } else {
            notifyError()
        }
    }

    // Delete function to handle User Data

    const handleDeleteSocialMediaLink = async (id) => {
        let response = fetch(`${SOCIALMEDIALINKS_URL}${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // console.log('response:', response)
        let status = (await response).status;
        if (status === 204) {
            notifyCustomSuccessMessage('Social media link deleted successfully!');
            const newInfo = socialMediaInfo.filter((link) => link.id !== id);
            setSocialMediaInfo(newInfo);
        } else {
            notifyError();
        }
    }

    const handleDelete = async (id) => {
        // alert(id)
        // console.log('Handle Delete function called');
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // console.log('response:', response)
        let status = (await response).status
        if (status === 204) {
            notifyCustomSuccessMessage('Address deleted successfully!');
            // if (type === 'permanent') {
            //     setPermanentAddress('')
            // } else {
            const newAddresses = presentAddress.filter((address) => address.id !== id);
            setPresentAddress(newAddresses)
            // }
        } else {
            // alert('Something went wrong!')
            notifyError()
        }
    }

    // handle change address from other's to present

    const handleChangePresentAddress = async (psAddressId) => {
        // setAddressStatus(event.target.checked);
        // console.log('Address Id:', psAddressId);
        let response;
        if (pmAddressId !== null)
            response = handleChangePresentAddressSubmit(psAddressId, pmAddressId, user.user_id);
        else
            response = handleSetPresentAddressSubmit(psAddressId, user.user_id);
        // let data = (await response).json()
        // console.log('data:', data)
        let status = (await response).status
        // console.log('status:', status)
        if (status === 200) {
            notifyCustomSuccessMessage('Address changed successfully.');
            setIsAddressChanged(true);
        } else {
            notifyError();
        }
    }

    const handleChangePmAddress = () => {
        setIsPmAddress(!isPmAddress);
    }

    // Handle radio button change
    const handleRadioChange = (item, category) => {
        // setSelections({ ...selections, [itemId]: category });
        if (item === 'Player') {
            if (!selectedPlayerSwitchOption)
                notifyCustomWarningMessage('Inactive profile cannot be current profile.')
            else {
                setSelectedPlayerStatusOption({ 'profile_type': item, 'status': category })
                setSelectedCoachStatusOption({ 'profile_type': item, 'status': 'Not Current' })
                setSelectedAgentStatusOption({ 'profile_type': item, 'status': 'Not Current' })
                // setSelectedPlayerSwitchOption(false)

                // Prepare the data to be sent to the server
                const postData = {
                    id: '',
                    profile_type: 'Player',
                    status: category,
                    user: user.user_id
                };

                console.log('postData:', JSON.stringify(postData));

                handlePostSportProfileChangeUserStatus(postData);
            }
        }
        else if (item === 'Coach') {
            if (!selectedCoachSwitchOption)
                notifyCustomWarningMessage('Inactive profile cannot be current profile.')
            else {
                setSelectedCoachStatusOption({ 'profile_type': item, 'status': category })
                setSelectedAgentStatusOption({ 'profile_type': item, 'status': 'Not Current' })
                setSelectedPlayerStatusOption({ 'profile_type': item, 'status': 'Not Current' })
                // setSelectedCoachSwitchOption(false)

                // Prepare the data to be sent to the server
                const postData = {
                    profile_type: 'Coach',
                    status: category,
                    user: user.user_id
                };

                console.log('postData:', JSON.stringify(postData));

                handlePostSportProfileChangeUserStatus(postData);
            }
        }
        else if (item === 'Agent') {
            if (!selectedAgentSwitchOption)
                notifyCustomWarningMessage('Inactive profile cannot be current profile.')
            else {
                setSelectedAgentStatusOption({ 'profile_type': item, 'status': category })
                setSelectedCoachStatusOption({ 'profile_type': item, 'status': 'Not Current' })
                setSelectedPlayerStatusOption({ 'profile_type': item, 'status': 'Not Current' })
                // setSelectedAgentSwitchOption(false)

                // Prepare the data to be sent to the server
                const postData = {
                    profile_type: 'Agent',
                    status: category,
                    user: user.user_id
                };

                console.log('postData:', JSON.stringify(postData));

                handlePostSportProfileChangeUserStatus(postData);
            }
        }
    };

    // Handle switch toggle
    const handleSwitchToggle = (item) => {
        console.log('handleSwitchToggle function called')
        if (item === 'Player') {
            if (selectedPlayerStatusOption.status === 'Current' && selectedPlayerSwitchOption) {
                setShowModal(true);
            } else {
                setSelectedPlayerStatusOption({
                    ...selectedPlayerStatusOption,
                    'is_active': selectedPlayerSwitchOption,
                });
                setSelectedPlayerSwitchOption(!selectedPlayerSwitchOption)
                console.log('selections:', selectedPlayerStatusOption)
                console.log('selected switch options:', selectedPlayerSwitchOption)

                // Prepare the data to be sent to the server
                const postData = {
                    profile_type: 'Player',
                    status: selectedPlayerStatusOption.status,
                    is_active: !selectedPlayerSwitchOption,
                    user: user.user_id
                };

                console.log('postData:', JSON.stringify(postData));

                handlePostSportProfile(postData);
            }
        } else if (item === 'Coach') {
            if (selectedCoachStatusOption.status === 'Current' && selectedCoachSwitchOption) {
                setShowModal(true);
            } else {
                setSelectedCoachStatusOption({
                    ...selectedCoachStatusOption,
                    'is_active': selectedCoachSwitchOption,
                });
                setSelectedCoachSwitchOption(!selectedCoachSwitchOption)
                console.log('selections:', selectedCoachStatusOption)
                console.log('selected switch options:', selectedCoachSwitchOption)

                // Prepare the data to be sent to the server
                const postData = {
                    profile_type: 'Coach',
                    status: selectedCoachStatusOption.status,
                    is_active: !selectedCoachSwitchOption,
                    user: user.user_id
                };

                console.log('postData:', JSON.stringify(postData));

                handlePostSportProfile(postData);
            }
        } else if (item === 'Agent') {
            if (selectedAgentStatusOption.status === 'Current' && selectedAgentSwitchOption) {
                setShowModal(true);
            } else {
                setSelectedAgentStatusOption({
                    ...selectedAgentStatusOption,
                    'is_active': selectedAgentSwitchOption,
                });
                setSelectedAgentSwitchOption(!selectedAgentSwitchOption)
                console.log('selections:', selectedAgentStatusOption)
                console.log('selected switch options:', selectedAgentSwitchOption)

                // Prepare the data to be sent to the server
                const postData = {
                    profile_type: 'Agent',
                    status: selectedAgentStatusOption.status,
                    is_active: !selectedAgentSwitchOption,
                    user: user.user_id
                };

                console.log('postData:', JSON.stringify(postData));

                handlePostSportProfile(postData);
            }
        }
    };

    // handle POST Sport Profile

    const handlePostSportProfileChangeUserStatus = (postData) => {
        // Make a POST request to the server
        // fetch(`${SPORTPROFILETYPECHANGEUSERSTATUS_URL}`, {
        fetch(`${CHANGESPORTPROFILE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // Add any additional headers if needed
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                // Check if response is ok
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Handle successful response
                console.log('Data posted successfully');
                // console.log(response);
                notifyRegistrationPersonalInfo();
                // updateToken();
                // setIsLoading(true);
            })
            .catch(error => {
                // Handle fetch errors
                console.error('Error posting data:', error);
                notifyError();
            });
    }

    const handlePostSportProfile = (postData) => {
        // Make a POST request to the server
        fetch(`${SPORTPROFILE_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // Add any additional headers if needed
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                // Check if response is ok
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Handle successful response
                console.log('Data posted successfully');
                notifyRegistrationPersonalInfo();
                setFlag(true);
                // updateToken();
                // setIsLoading(true);
            })
            .catch(error => {
                // Handle fetch errors
                console.error('Error posting data:', error);
                notifyError();
            });
    }

    // handle modal close

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleEditPersonalModalClose = () => {
        setShowEditPersonalModal(false)
        setFirstName('')
        setFirstNameError('')
        setLastName('')
        setLastNameError('')
        setHeight('')
        setWeight('')
        setDob('')
        // setDobError('')
        setDobAgeError('')
        setSportType('')
        setSportTypeError('')
        setContactNo('')
        // setContactNoError('')
        setCountryCode('')
        setCountryCodeError('')
        setSelectedFile(null)
        setValidatedPersonalInfo(false)
    }

    const handleEditProfileDescriptionModalClose = () => {
        setShowEditProfileDescriptionModal(false)
        setProfileDescription('')
        setProfileDescriptionError('')
    }

    const handleCreateSocialMediaLinksModalClose = () => {
        setShowCreateSocialMediaLinksModal(false);
        setValidatedSocialMediaLinks(false);
        setSocialMediaLink('');
        setSocialMediaPlatform('');
        setSocialMediaTitle('');
    }

    const handleEditSocialMediaLinksModalClose = () => {
        setShowEditSocialMediaLinksModal(false);
        setValidatedSocialMediaLinks(false);
        setSocialMediaId(null);
        setSocialMediaLink('');
        setSocialMediaPlatform('');
        setSocialMediaTitle('');
    }

    const handleCreateAddressModalClose = () => {
        setShowCreateAddressModal(false)
        setAddressId(null)
        setAddressLane('')
        setAddressLaneError('')
        setAddressType('')
        setAddressTypeError('')
        setCity('')
        setCityError('')
        setState('')
        setStateError('')
        setPin('')
        setPinError('')
        setCountry('')
        setCountryError('')
    }

    const handleEditAddressModalClose = () => {
        setShowEditAddressModal(false)
        setAddressId(null)
        setAddressLane('')
        setAddressLaneError('')
        setAddressType('')
        setAddressTypeError('')
        setCity('')
        setCityError('')
        setState('')
        setStateError('')
        setPin('')
        setPinError('')
        setCountry('')
        setCountryError('')
    }

    // handle user personal information

    const userPersonalInfo = async () => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json();
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status;
        if (status === 200) {
            const personalInfo = (await data);
            // console.log('personalInfo: ', personalInfo)
            setUserPersonalData(personalInfo);
            setFirstName(personalInfo.first_name);
            setLastName(personalInfo.last_name);
            setDob(personalInfo.dob);
            setHeight(personalInfo.height);
            setWeight(personalInfo.weight);
            setSportType(personalInfo.sport_type);
            setCountryCode(personalInfo.country_code);
            const pmAddress = (await personalInfo.permanent_address[0]);
            // console.log('Profile, permenent address: ', pmAddress)
            if (pmAddress) {
                setPermanentAddress(pmAddress);
                setPmAddressId(pmAddress.id);
            } else {
                setPermanentAddress('');
            }
            const psAddress = (await personalInfo.present_address);
            // console.log('Profile, present address: ', psAddress)
            setPresentAddress(psAddress);
            // const desc = (await personalInfo.profile_description)
            // console.log('Profile, profile description: ', desc)
            // setProfile(desc)
            const sportProfileType = (await personalInfo.sport_profile_type);
            setSportProfile(sportProfileType);
            sportProfileType.map(item => {
                if (item.profile_type === 'Player') {
                    setSelectedPlayerStatusOption({ 'profile_type': item.profile_type, 'status': item.status });
                    if (item.is_active === true)
                        setSelectedPlayerSwitchOption(true);
                    else
                        setSelectedPlayerSwitchOption(false);
                }
                if (item.profile_type === 'Coach') {
                    setSelectedCoachStatusOption({ 'profile_type': item.profile_type, 'status': item.status });
                    if (item.is_active === true)
                        setSelectedCoachSwitchOption(true);
                    else
                        setSelectedCoachSwitchOption(false);
                }
                if (item.profile_type === 'Agent') {
                    setSelectedAgentStatusOption({ 'profile_type': item.profile_type, 'status': item.status });
                    if (item.is_active === true)
                        setSelectedAgentSwitchOption(true);
                    else
                        setSelectedAgentSwitchOption(false);
                }
            })
            const socialmedialinks = (await personalInfo.social_media_links);
            console.log('social_media_links', socialmedialinks);
            setSocialMediaInfo(socialmedialinks);
        } else if (status === 401) {
            notifyAuthenticateError();
        } else {
            notifyError();
        }
    }

    useEffect(() => {
        userPersonalInfo();
        console.log('flag', flag);
        // setFlag(user.is_flag);

        // function simulateNetworkRequest() {
        //     return new Promise((resolve) => setTimeout(resolve, 2000));
        // }

        // if (isLoading) {
        //     simulateNetworkRequest().then(() => {
        //         setIsLoading(false);
        //     });
        // }
    }, [showEditPersonalModal, showEditProfileDescriptionModal, showCreateSocialMediaLinksModal, showEditSocialMediaLinksModal, showCreateAddressModal, showEditAddressModal, isAddressChanged]);

    const socialMediaLinkList = socialMediaInfo.map((social) => {
        return (
            <tr key={social.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getSocialMediaInfo(social.id);
                            setShowEditSocialMediaLinksModal(true);
                        }}
                    />
                </td>
                <td>{social.platform}</td>
                <td>
                    <Link to={social.link}>
                        {social.link}
                    </Link>
                </td>
                <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDeleteSocialMediaLink(social.id)}
                    />
                </td>
            </tr>
        );
    })

    const presentAddressList = presentAddress.map((address) => {
        return (
            <tr key={address.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getPresentAddressInfo(address.id);
                            setShowEditAddressModal(true);
                        }}
                    />
                </td>
                <td>{address.address_lane}</td>
                <td>{address.landmark}</td>
                <td>{address.city}</td>
                <td>{address.pin}</td>
                <td>{address.state}</td>
                <td>{address.country}</td>
                <td>{address.address_type}</td>
                <td>
                    <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            // checked={addressStatus}
                            onChange={(e) => handleChangePresentAddress(address.id)}
                        />
                    </Form>
                </td>
                <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(address.id)}
                    />
                </td>
            </tr>
        );
    })

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles) => {
            return {
                ...styles,

                ':hover': {
                    backgroundColor: "#6d6c6c",
                    color: 'white',
                },
            };
        },
    };

    return (
        <>

            <Modal
                show={showModal}
                onHide={handleModalClose}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Warning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <h4>Warning</h4> */}
                    <p>
                        Current Profile cannot be inactive.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditPersonalModal} onHide={handleEditPersonalModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Personal Information</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedPersonalInfo}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>First Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your first name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {firstNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Last Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your last name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {lastNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Sport Type
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    className='input'
                                    size='lg'
                                    value={sportType}
                                    onChange={(e) => setSportType(e.target.value)}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Football">Football</option>
                                    <option value="Cricket">Cricket</option>
                                    <option value="Badminton">Badminton</option>
                                    <option value="Long Tennis">Long Tennis</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {sportTypeError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Height</Form.Label>
                                <Form.Group as={Row}>
                                    <Col>
                                        <Form.Control
                                            className='input'
                                            type="number"
                                            size='lg'
                                            placeholder="Enter height"
                                            value={height}
                                            onChange={(e) => setHeight(e.target.value)}
                                        />
                                    </Col>
                                    <Form.Label column sm="2">
                                        in cm
                                    </Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Weight</Form.Label>
                                <Form.Group as={Row}>
                                    <Col>
                                        <Form.Control
                                            className='input'
                                            type="number"
                                            size='lg'
                                            placeholder="Enter weight"
                                            value={weight}
                                            onChange={(e) => setWeight(e.target.value)}
                                        />
                                    </Col>
                                    <Form.Label column sm="2">
                                        in kg
                                    </Form.Label>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Date of Birth
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="date"
                                    size='lg'
                                    placeholder="Enter your date of birth"
                                    value={dob}
                                    onChange={(e) => setDob(e.target.value)}
                                />
                                {dobAgeError && <p style={{ color: 'red' }}>{dobAgeError}</p>}
                            </Form.Group>
                        </Row>

                        <Row className="mb-4">
                            <Form.Group as={Col} sm="2">
                                <Form.Label className='custom-label'>Country Code</Form.Label>
                                <Form.Select
                                    className='input'
                                    size='lg'
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                >
                                    <option value="">Open select menu</option>
                                    <option value="+61">Australia (+61)</option>
                                </Form.Select>
                                {countryCodeError && <p style={{ color: 'red' }}>{countryCodeError}</p>}
                            </Form.Group>
                            <Form.Group as={Col} sm="2">
                                <Form.Label className='custom-label'>Contact Number</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="number"
                                    size='lg'
                                    placeholder="Enter the contact number"
                                    value={contactNo}
                                    onChange={(e) => setContactNo(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Profile Photo</Form.Label>
                                <Form.Control
                                    type="file"
                                    size='lg'
                                    // value={selectedFile}
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Citizenship</Form.Label>
                                <Select
                                    value={citizenship}
                                    onChange={(e) => setCitizenship(e)}
                                    options={optionsCountry}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#6d6c6c',
                                        },
                                    })}
                                    styles={colourStyles}
                                    isMulti
                                    isSearchable
                                    isClearable
                                    required
                                />
                            </Form.Group>

                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Languages Spoken</Form.Label>
                                <Select
                                    value={languageSpoken}
                                    onChange={(e) => setLanguageSpoken(e)}
                                    options={optionsLanguageSpoken}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#6d6c6c',
                                        },
                                    })}
                                    styles={colourStyles}
                                    isMulti
                                    isSearchable
                                    isClearable
                                    required
                                />
                            </Form.Group>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditPersonalModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditProfileDescriptionModal} onHide={handleEditProfileDescriptionModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Profile Description</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedAboutMe}>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Description
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    as="textarea"
                                    rows={6}
                                    placeholder="Enter the description"
                                    value={profileDescription}
                                    onChange={(e) => setProfileDescription(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {profileDescriptionError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditProfileDescriptionModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleUpdateProfileDescription}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showCreateSocialMediaLinksModal} onHide={handleCreateSocialMediaLinksModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Add Social Media Links</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedSocialMediaLinks}>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Platform
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    className='input'
                                    size='lg'
                                    value={socialMediaPlatform}
                                    onChange={(e) => setSocialMediaPlatform(e.target.value)}
                                    // style={{ fontSize: 11, padding: 10 }}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Others">Others</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Platform cannot be empty
                                </Form.Control.Feedback>
                            </Form.Group>
                            {socialMediaPlatform === 'Others' &&
                                <Form.Group as={Col} sm="4">
                                    <Form.Label className='custom-label'>Title
                                        <span className='text-danger'> * </span>
                                    </Form.Label>
                                    <Form.Control
                                        className='input'
                                        type='text'
                                        size='lg'
                                        placeholder="Enter the title of your link"
                                        value={socialMediaTitle}
                                        onChange={(e) => setSocialMediaTitle(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Title cannot be empty
                                    </Form.Control.Feedback>
                                </Form.Group>
                            }
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Link
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter the link/url to your profile"
                                    value={socialMediaLink}
                                    onChange={(e) => setSocialMediaLink(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Link cannot be empty
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCreateSocialMediaLinksModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleSubmitSocialMediaLinks}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditSocialMediaLinksModal} onHide={handleEditSocialMediaLinksModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Edit Social Media Links</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedSocialMediaLinks}>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Platform
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    className='input'
                                    size='lg'
                                    value={socialMediaPlatform}
                                    onChange={(e) => setSocialMediaPlatform(e.target.value)}
                                    // style={{ fontSize: 11, padding: 10 }}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Others">Others</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Platform cannot be empty
                                </Form.Control.Feedback>
                            </Form.Group>
                            {socialMediaPlatform === 'Others' &&
                                <Form.Group as={Col} sm="4">
                                    <Form.Label className='custom-label'>Title
                                        <span className='text-danger'> * </span>
                                    </Form.Label>
                                    <Form.Control
                                        className='input'
                                        type='text'
                                        size='lg'
                                        placeholder="Enter the title of your link"
                                        value={socialMediaTitle}
                                        onChange={(e) => setSocialMediaTitle(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Title cannot be empty
                                    </Form.Control.Feedback>
                                </Form.Group>
                            }
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Link
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter the link/url to your profile"
                                    value={socialMediaLink}
                                    onChange={(e) => setSocialMediaLink(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Link cannot be empty
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditSocialMediaLinksModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleUpdateSocialMediaLinks}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showCreateAddressModal} onHide={handleCreateAddressModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Address</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedAddresses}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Address Lane</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter your address lane"
                                    value={addressLane}
                                    onChange={(e) => setAddressLane(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} className="d-flex justify-content-end">
                                <Form.Check
                                    type="switch"
                                    size="lg"
                                    label="Mark as permanent address"
                                    className='custom-label'
                                    checked={isPmAddress}
                                    onChange={handleChangePmAddress}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Landmark</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your landmark"
                                    value={landmark}
                                    onChange={(e) => setLandmark(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>City
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {cityError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Zip Code</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your zip code"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>State</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Country
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    // style={{ fontSize: 11, padding: 10 }}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    {optionsCountry.map((country) => (
                                        <option value={country.value}>
                                            {country.label}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {countryError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Address Type
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={addressType}
                                    onChange={(e) => setAddressType(e.target.value)}
                                    // style={{ fontSize: 11, padding: 10 }}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Both">Both</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        {/* <Row className='mb-3'>
                            <Form.Group as={Col}>
                                <Form.Check
                                    type="checkbox"
                                    size="lg"
                                    label="Mark as permanent address"
                                    className='custom-label'
                                    checked={isPmAddress}
                                    onChange={handleChangePmAddress}
                                />
                            </Form.Group>
                        </Row> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCreateAddressModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleSubmitPresentAddress}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditAddressModal} onHide={handleEditAddressModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Address</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedAddresses}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Address Lane</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter your address lane"
                                    value={addressLane}
                                    onChange={(e) => setAddressLane(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Landmark</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your landmark"
                                    value={landmark}
                                    onChange={(e) => setLandmark(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>City
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {cityError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Zip Code</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your zip code"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {pinError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>State</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Country
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    // style={{ fontSize: 11, padding: 10 }}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    {optionsCountry.map((country) => (
                                        <option value={country.value}>
                                            {country.label}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {countryError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Address Type</Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={addressType}
                                    onChange={(e) => setAddressType(e.target.value)}
                                    // style={{ fontSize: 11, padding: 10 }}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Both">Both</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditAddressModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleUpdateAddress}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={flag} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <Container>
                <Row>
                    <ProfileBuilderNavBar
                        playerIsActive={selectedPlayerSwitchOption}
                        coachIsActive={selectedCoachSwitchOption}
                        agentIsActive={selectedAgentSwitchOption}
                        instituitionIsActive={false}
                    />

                    <Col xs={9}>
                        {!flag &&
                            <Alert variant="success" className='mt-3'>
                                You must have one active profile in "SELECT PROFILES" tab.
                            </Alert>
                        }
                        <Card className='mt-3'>
                            <Card.Body>
                                <h2 className='text-center'>Personal Information</h2>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                    fill
                                    variant='underline'
                                >
                                    <Tab eventKey="general" title="GENERAL">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Personal Information</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Edit</th>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Email</th>
                                                            <th>Contact No</th>
                                                            <th>Date of Birth</th>
                                                            <th>Height</th>
                                                            <th>Weight</th>
                                                            <th>Sport Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    color='#2222e785'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        getUserPersonalInfo();
                                                                        setShowEditPersonalModal(true);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{userPersonalData.first_name}</td>
                                                            <td>{userPersonalData.last_name}</td>
                                                            <td>{userPersonalData.email}</td>
                                                            <td>{userPersonalData.contact_no === null && 'null' ? '' : userPersonalData.contact_no}</td>
                                                            <td>{userPersonalData.dob}</td>
                                                            <td>{userPersonalData.height === 0 ? '' : userPersonalData.height}</td>
                                                            <td>{userPersonalData.weight === 0 ? '' : userPersonalData.weight}</td>
                                                            <td>{userPersonalData.sport_type}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Tab>

                                    <Tab eventKey="aboutme" title="ABOUT ME">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Summary</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Edit</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    color='#2222e785'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        getUserPersonalInfo();
                                                                        setShowEditProfileDescriptionModal(true);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{userPersonalData.bio}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>

                                        <Stack direction="horizontal">
                                            <div className="p-2 ms-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setShowCreateSocialMediaLinksModal(true)}
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Stack>
                                        <div style={{ height: '350px', overflowY: 'scroll' }}>
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Social Media Links</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Social Media Platform</th>
                                                                <th>Social Media Links</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                socialMediaInfo && socialMediaInfo.length > 0 ?
                                                                    socialMediaLinkList
                                                                    :
                                                                    <tr>
                                                                        <th colSpan={10} className='text-center'>
                                                                            No data found
                                                                        </th>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="addresses" title="ADDRESSES">
                                        <Stack direction="horizontal">
                                            <div className="p-2 ms-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setShowCreateAddressModal(true)}
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Stack>
                                        <div style={{ height: '350px', overflowY: 'scroll' }}>
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Present Address</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Address Lane</th>
                                                                <th>Landmark</th>
                                                                <th>City</th>
                                                                <th>Zip Code</th>
                                                                <th>State</th>
                                                                <th>Country</th>
                                                                <th>Address Type</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {permanentAddress !== '' ?
                                                                <tr>
                                                                    <td>
                                                                        <FontAwesomeIcon
                                                                            icon={faEdit}
                                                                            color='#2222e785'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                getAddressInfo(permanentAddress.id);
                                                                                setShowEditAddressModal(true);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>{permanentAddress.address_lane}</td>
                                                                    <td>{permanentAddress.landmark}</td>
                                                                    <td>{permanentAddress.city}</td>
                                                                    <td>{permanentAddress.pin}</td>
                                                                    <td>{permanentAddress.state}</td>
                                                                    <td>{permanentAddress.country}</td>
                                                                    <td>{permanentAddress.address_type}</td>
                                                                    <td>
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashCan}
                                                                            color='red'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleUpdatePermanentAddress(permanentAddress.id)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <th colSpan={9} className='text-center'>
                                                                        No data found
                                                                    </th>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>

                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Other Addresses</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Address Lane</th>
                                                                <th>Landmark</th>
                                                                <th>City</th>
                                                                <th>Zip Code</th>
                                                                <th>State</th>
                                                                <th>Country</th>
                                                                <th>Address Type</th>
                                                                <th>Mark as Permanent</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                presentAddress && presentAddress.length > 0 ?
                                                                    presentAddressList
                                                                    :
                                                                    <tr>
                                                                        <th colSpan={10} className='text-center'>
                                                                            No data found
                                                                        </th>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="selectprofiles" title="SELECT PROFILES">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Profile</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Profile Type</th>
                                                            <th>Profile Status</th>
                                                            <th>Active/Inactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Player</td>
                                                            <td>
                                                                <div>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="radioGroup-player"
                                                                        label="Current"
                                                                        value="Current"
                                                                        checked={selectedPlayerStatusOption['status'] === 'Current'}
                                                                        onChange={() => handleRadioChange('Player', 'Current')}
                                                                    />
                                                                    {/* <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="radioGroup-player"
                                                                        label="Not Current"
                                                                        value="Not Current"
                                                                        checked={selectedPlayerStatusOption['status'] === 'Not Current'}
                                                                        onChange={() => handleRadioChange('Player', 'Not Current')}
                                                                    /> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="custom-switch-player"
                                                                    label={selectedPlayerSwitchOption ? 'Active' : 'Inactive'}
                                                                    // label="Toggle"
                                                                    // checked={selections[item.id] === 'Current'}
                                                                    checked={selectedPlayerSwitchOption}
                                                                    onChange={() => handleSwitchToggle('Player')}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Coach</td>
                                                            <td>
                                                                <div>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="radioGroup-coach"
                                                                        label="Current"
                                                                        value="Current"
                                                                        checked={selectedCoachStatusOption['status'] === 'Current'}
                                                                        onChange={() => handleRadioChange('Coach', 'Current')}
                                                                    />
                                                                    {/* <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="radioGroup-coach"
                                                                        label="Not Current"
                                                                        value="Not Current"
                                                                        checked={selectedCoachStatusOption['status'] === 'Not Current'}
                                                                        onChange={() => handleRadioChange('Coach', 'Not Current')}
                                                                    /> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="custom-switch-coach"
                                                                    label={selectedCoachSwitchOption ? 'Active' : 'Inactive'}
                                                                    // label="Toggle"
                                                                    // checked={selections[item.id] === 'Current'}
                                                                    checked={selectedCoachSwitchOption}
                                                                    onChange={() => handleSwitchToggle('Coach')}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Agent</td>
                                                            <td>
                                                                <div>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="radioGroup-agent"
                                                                        label="Current"
                                                                        value="Current"
                                                                        checked={selectedAgentStatusOption['status'] === 'Current'}
                                                                        onChange={() => handleRadioChange('Agent', 'Current')}
                                                                    />
                                                                    {/* <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        name="radioGroup-agent"
                                                                        label="Not Current"
                                                                        value="Not Current"
                                                                        checked={selectedAgentStatusOption['status'] === 'Not Current'}
                                                                        onChange={() => handleRadioChange('Agent', 'Not Current')}
                                                                    /> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="custom-switch-agent"
                                                                    label={selectedAgentSwitchOption ? 'Active' : 'Inactive'}
                                                                    // label="Toggle"
                                                                    // checked={selections[item.id] === 'Current'}
                                                                    checked={selectedAgentSwitchOption}
                                                                    onChange={() => handleSwitchToggle('Agent')}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
            <ToastContainer autoClose={2000} />
        </>
    )
}
