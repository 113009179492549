import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
// import './Style.css';
import BlankProfilePicture from '../assets/blank-profile-picture.png'
// import FootballPicture from '../assets/football-488714_1280.jpg'
import { useNavigate } from 'react-router-dom';
import { Spinner, Nav, Stack, Image, Modal, Button, Collapse, Form, ListGroup, Col, Row, Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faChevronDown, faChevronUp, faCircle, faCircleDot, faEnvelope, faImage, faMessage, faNewspaper, faPhotoFilm, faThumbsUp, faUser, faUserGroup, faVideo } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import { handleDeleteLike, handlePost, handleSubmitComments, handleSubmitLikes, handleNetworkConnectSubmit, handleEventPost, handleNetworkConnectingSubmit } from '../services/ApiServices';
import AuthContext from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostCard } from '../components/CustomComponents';
import { NetworkContext } from '../context/NetworkContext';
import { GEOCODDING_LOCATION_API_KEY } from '../services/Constants';

export default function Home() {

  let {
    currentUser,
    postData,
    news,
    getPostData,
    getNews,
    userPersonalData,
    sportProfileType,
    userPersonalInfo,
    notifyRegistrationPersonalInfo,
    notifyError
  } = useContext(AuthContext)

  const {
    getNetworkConnectRequest,
    getNetworkConnections,
    pendingNetworkRequest,
    connectedNetworkRequest
  } = useContext(NetworkContext);

  const navigate = useNavigate();

  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingNormalPost, setLoadingNormalPost] = useState(true);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [loadingEventGeoLocation, setLoadingEventGeoLocation] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isPrivacyDisabled, setIsPrivacyDisabled] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [likesData, setLikesData] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  // const [networkConnected, setNetworkConnected] = useState([]);
  // const [networkPendingRequest, setNetworkPendingRequest] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLoadMoreComments, setOpenLoadMoreComments] = useState(false);
  const [show, setShow] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const [showLikesModal, setShowLikesModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [shareImage, setShareImage] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [assetArea, setAssetArea] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [eventlocation, setEventLocation] = useState('');
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  // const [latitude, setLatitude] = useState('');
  // const [longitude, setLongitude] = useState('');
  const [locationError, setLocationError] = useState(null);
  const [privacyType, setPrivacyType] = useState('');
  const [postType, setPostType] = useState('');
  const [loadPost, setLoadPost] = useState(false);
  const [loadMoreCounter, setLoadMoreCounter] = useState(10);
  // const [playerCurrentClub, setPlayerCurrentClub] = useState('');
  // const [coachCurrentClub, setCoachCurrentClub] = useState('');
  // const [sportProfileType, setSportProfileType] = useState('');
  const [validated, setValidated] = useState(false);
  // const [flag, setFlag] = useState(false);

  const handleChange = (e) => {
    const image = e.target.files[0];

    if (image === '' || image === undefined) {
      alert(`not an image, the file is a ${typeof image}`);
      return;
    }

    setShareImage(image);
  }

  const switchAssetArea = (area) => {
    // setShareImage('');
    // setVideoLink('');
    setAssetArea(area);
  }

  const navigateToSearchDataWithParams = (item) => {
    if (item.account_type === 'user')
      navigate(`/user/viewprofile?user_id=${item.id}`);
    else
      navigate(`/institute/viewprofile?institute_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    // console.log('navigateToSearchAllWithParams');
    // console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  const navigateToChatWithParams = (param1) => {
    navigate(`/messaging?param1=${param1}`);
  };

  const handleClose = () => {
    setAssetArea('');
    setVideoLink('');
    setShareImage('');
    setShow(false);
    setCoordinates({ latitude: null, longitude: null });
    setEventLocation('');
    setLoadingEventGeoLocation(false);
  }

  // const handleCloseEvent = () => {
  //   setAssetArea('');
  //   setShareImage('');
  //   setShowEvent(false);
  // }

  const handleShow = () => setShow(true);

  const handleShowEvent = () => setShowEvent(true);

  const handleShowLikesModal = (likes) => {
    setShowLikesModal(true);
    setLikesData(likes);
  }

  const handleCloseLikesModal = () => setShowLikesModal(false);

  const handleShowCommentsModal = (comments) => {
    setShowCommentsModal(true);
    setCommentsData(comments);
  }

  const handleCloseCommentsModal = () => {
    setShowCommentsModal(false);
    setLoadMoreCounter(10);
  }

  const handlePostRequest = async () => {
    setValidated(true);
    const formData = new FormData();
    formData.append("user", currentUser.user_id);
    if (privacyType !== '') {
      formData.append("type", privacyType);
    } else {
      return;
    }
    if (postType !== '') {
      formData.append("post_type", postType);
    } else {
      return;
    }
    if (description !== '') {
      formData.append("description", description);
    }
    if (shareImage !== '') {
      formData.append("picture", shareImage);
    }
    if (videoLink !== '') {
      formData.append("video_link", videoLink);
    }
    const response = handlePost(formData);
    // console.log('response from handlePost:', response);
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo();
      setShow(false);
      setLoadPost(true);
    } else {
      notifyError();
    }
  }

  const handlePostEventRequest = async () => {
    setValidated(true);
    const formData = new FormData();
    formData.append("user", currentUser.user_id);
    if (privacyType !== '') {
      formData.append("type", privacyType);
    } else {
      return;
    }
    if (title !== '') {
      formData.append("title", title);
    } else {
      return;
    }
    if (description !== '') {
      formData.append("content", description);
    }
    if (shareImage !== '') {
      formData.append("picture", shareImage);
    }
    if (startDate !== '') {
      formData.append("start_date", startDate);
    } else {
      return;
    }
    if (lastDate !== '') {
      formData.append("end_date", lastDate);
    } else {
      return;
    }
    if (eventlocation !== '') {
      formData.append("location", eventlocation);
      formData.append("latitude", coordinates.latitude);
      formData.append("longitude", coordinates.longitude);
      setLocationError('');
    } else {
      setLocationError('Please select an address to continue.');
      return;
    }
    const response = handleEventPost(formData);
    // console.log('response from handlePost:', response);
    // let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo();
      setShowEvent(false);
      setShow(false);
      setLoadPost(true);
    } else {
      notifyError();
      setLocationError('');
      setEventLocation('');
      setCoordinates({ latitude: null, longitude: null });
    }
  }

  const clearFields = (event) => {
    // we have to convert event.target to array
    // we use from method to convert event.target to array
    // after that we will use forEach function to go through every input to clear it
    Array.from(event.target).forEach((e) => (e.value = ""));
  }

  const handleSubmit = async (event, postId, currentUser) => {
    event.preventDefault();
    // console.log('data: ', event.target.comment.value);
    // console.log('user: ', currentUser.user_id);
    // console.log('post id: ', postId);
    const response = handleSubmitComments(event, currentUser, postId);
    // console.log('response from handlePost:', response);
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      getPostData();
      clearFields(event);
      // event.target.comment.reset();
      // reset();
    } else {
      notifyError();
    }
  }

  const handleLikes = async (postId, currentUser) => {
    // event.preventDefault();
    // console.log('data: ', event.target.comment.value);
    setButtonDisabled(true);
    // console.log('user: ', currentUser.user_id);
    // console.log('post id: ', postId);
    const response = handleSubmitLikes(currentUser, postId);
    // console.log('response from handlePost:', response);
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      getPostData();
      setTimeout(() => {
        setButtonDisabled(false)
      }, 6000);
    } else {
      notifyError();
      setTimeout(() => {
        setButtonDisabled(false)
      }, 4000);
    }
  }

  const handleDisLikes = async (postId) => {
    // event.preventDefault();
    // console.log('data: ', event.target.comment.value);
    // console.log('user: ', currentUser.user_id);
    setButtonDisabled(true);
    // console.log('post id: ', postId);
    const response = handleDeleteLike(postId);
    // console.log('response from handlePost:', response);
    // let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 204) {
      getPostData();
      setTimeout(() => {
        setButtonDisabled(false)
      }, 6000);
    } else {
      notifyError();
      setTimeout(() => {
        setButtonDisabled(false)
      }, 4000);
    }
  }

  const HandleLoadMoreCommments = ({ postedComment, upperlimit, lowerlimit }) => {

    const commentsPosted = postedComment.slice(upperlimit, lowerlimit).map((postedComment) => {

      const isoDateString = postedComment.posted;
      const date = new Date(isoDateString);
      const localDateString = date.toLocaleDateString();
      const localTimeString = date.toLocaleTimeString();

      let currentProfile = '';
      let currentClub = '';

      postedComment.user.sport_profile_type.forEach((type) => {
        if (type.is_active) {
          currentProfile = type.profile_type;
          if (currentProfile.toLowerCase() === 'player') {
            postedComment.user.player.forEach((player) => {
              currentClub = 'at ' + player.current_club_inside_name;
            });
          } else if (currentProfile.toLowerCase() === 'coach') {
            postedComment.user.coach.forEach((coach) => {
              currentClub = 'at ' + coach.current_team;
            });
          }
        }
      });

      return (
        <Stack direction="horizontal" gap={2} className='mt-2'>
          <Image src={postedComment.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
          <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
            <div className='justify-content-between d-flex' style={{ color: 'black' }}>
              {postedComment.user.account_type === 'institute' ?
                <span>{postedComment.user.club_name}</span> :
                <span>{postedComment.user.first_name} {postedComment.user.last_name}</span>
              }
              <span>{localDateString} {localTimeString}</span>
            </div>
            <div className='mb-2 text-muted'>
              {/* <span>Player at FC barcelona</span> */}
              <span>{currentProfile} {currentClub}</span>
            </div>
            <span style={{ color: 'black' }}>{postedComment.comment}</span>
          </div>
        </Stack>
      );
    })

    return (
      <>
        {commentsPosted}
      </>
    )
  }

  const HandleLikesComponent = ({ postedLikes }) => {
    // console.log('postedLikes: ', postedLikes);

    const likesPosted = postedLikes.map((postedLike) => {

      let currentProfile = '';
      let currentClub = '';

      postedLike.user.sport_profile_type.forEach((type) => {
        if (type.is_active) {
          currentProfile = type.profile_type;
          if (currentProfile.toLowerCase() === 'player') {
            postedLike.user.player.forEach((player) => {
              currentClub = 'at ' + player.current_club_inside_name;
            });
          } else if (currentProfile.toLowerCase() === 'coach') {
            postedLike.user.coach.forEach((coach) => {
              currentClub = 'at ' + coach.current_team;
            });
          }
        }
      });

      return (
        <Stack direction="horizontal" gap={2} className='mt-2'>
          <Image src={postedLike.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
          <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
            <div className='justify-content-between d-flex' style={{ color: 'black' }}>
              {postedLike.user.account_type === 'institute' ?
                <span>{postedLike.user.club_name}</span> :
                <span>{postedLike.user.first_name} {postedLike.user.last_name}</span>
              }
            </div>
            <div className='mb-2 text-muted'>
              <span>{currentProfile} {currentClub}</span>
            </div>
          </div>
        </Stack >
      );
    })

    return (
      <>
        {likesPosted}
      </>
    )
  }

  const handleName = () => {
    if (sportProfileType.toLowerCase() === 'coach') {
      navigate(`/coach/home`);
    }
    else if (sportProfileType.toLowerCase() === 'player') {
      navigate(`/player/home`);
    }
    else if (sportProfileType.toLowerCase() === 'agent') {
      navigate(`/agent/home`);
    } else {
      navigate(`/instituition/home`);
    }
  }

  const navigateToProfileWithParams = (item) => {
    // console.log('navigateToProfileWithParams');
    // console.log('Search Data:', item);
    // item.sport_profile_type.forEach((profile) => {
    //   if (profile.is_active) {
    //     if (profile.profile_type === 'Player') {
    //       console.log('player');
    //       navigate(`/player/searchplayer?param=${item.id}`);
    //     } else if (profile.profile_type === 'Coach') {
    //       console.log('coach');
    //       navigate(`/coach/searchcoach?param=${item.id}`);
    //     } else if (profile.profile_type === 'Agent') {
    //       console.log('agent');
    //       navigate(`/agent/searchagent?param=${item.id}`);
    //     } else if (profile.profile_type === 'Institution') {
    //       console.log('instituition');
    //       navigate(`/instituition/searchinstituition?param=${item.id}`);
    //     }
    //   } else {
    //     console.log('else part');
    //   }
    // })
    if (item.account_type === 'user')
      navigate(`/user/viewprofile?user_id=${item.id}`);
    else
      navigate(`/institute/viewprofile?institute_id=${item.id}`);
  };

  const getUserAddress = async (lat, lng) => {
    let url = `https://api.opencagedata.com/geocode/v1/json?key=${GEOCODDING_LOCATION_API_KEY}&q=${lat}%2C+${lng}&pretty=1&no_annotations=1`;
    const loc = await fetch(url)
    const data = await loc.json()
    console.log("User Address", data);
    setEventLocation(data.results[0].formatted);
  }

  const getCoordinates = async () => {
    // const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(eventlocation)}`;
    // try {
    //   const response = await fetch(url);
    //   const data = await response.json();
    //   console.log('coordinates responce:', response);
    //   console.log('coordinates data:', data);

    //   if (data && data.length > 0) {
    //     setCoordinates({ lat: data[0].lat, lng: data[0].lon });
    //     setLocationError(null);
    //   } else {
    //     setLocationError("Location not found.");
    //     setCoordinates({ lat: null, lng: null });
    //   }
    // } catch (err) {
    //   setLocationError("Error fetching coordinates. Please try again.");
    // }
    if (navigator.geolocation) {
      setLoadingEventGeoLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocationError('');
          getUserAddress(position.coords.latitude, position.coords.longitude);
          setLoadingEventGeoLocation(false);
        },
        (err) => {
          setLocationError(err.message);
          setLoadingEventGeoLocation(false);
        }
      );
    } else {
      setLocationError('Geolocation is not supported by your browser.');
    }
    // setLoadingEventGeoLocation(false);
  };

  useEffect(() => {
    userPersonalInfo();
    getPostData();
    getNetworkConnectRequest();
    getNetworkConnections();
    getNews();
    setSpinnerLoading(false);
    // setFlag(currentUser.is_flag);
    // console.log('news', news);
  }, [loadPost])

  useEffect(() => {
    let fourMinutes = 1000 * 60 * 1
    let interval = setInterval(() => {
      getPostData()
      getNews()
    }, fourMinutes)
    return () => clearInterval(interval)
  }, [])

  const postRequestList = postData.map((post) => {
    return (
      <PostCard
        post={post}
        currentUser={currentUser}
        isButtonDisabled={isButtonDisabled}
        handleSubmit={(event) => handleSubmit(event, post.id, currentUser)}
        handleLikes={() => handleLikes(post.id, currentUser)}
        handleDisLikes={(likeId) => handleDisLikes(likeId)}
        handleShowLikesModal={() => handleShowLikesModal(post.likes)}
        handleShowCommentsModal={() => handleShowCommentsModal(post.comments)}
        onClickMessage={() => navigateToChatWithParams(post.user.username)}
        onClickConnect={() => {
          // handleNetworkConnectSubmit(post.user, currentUser);
          handleNetworkConnectingSubmit(post.user, currentUser);
          getPostData();
        }}
        onClickUser={() => navigateToProfileWithParams(post.user)}
      />
    )
  })

  return (
    <>

      <Modal show={show} onHide={handleClose} size="lg">
        <Form noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='d-flex flex-row'>
                <Col>
                  {userPersonalData.profile_photo !== null ?
                    <Image src={userPersonalData.profile_photo} roundedCircle style={{ height: 50 }} />
                    :
                    <Image src={BlankProfilePicture} roundedCircle style={{ height: 50 }} />
                  }
                </Col>
                <Col sm="auto ms-2">
                  {currentUser.account_type !== 'institute' ? userPersonalData.first_name + ' ' + userPersonalData.last_name : userPersonalData.club_name}<br />
                  <Form.Select
                    size="sm"
                    value={privacyType}
                    onChange={(e) => setPrivacyType(e.target.value)}
                    required
                    disabled={isPrivacyDisabled}
                  >
                    <option value="">Select Privacy</option>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a privacy.
                  </Form.Control.Feedback>
                </Col>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Nav fill variant="pills" defaultActiveKey="link-1">
              <Nav.Item onClick={() => { setLoadingNormalPost(true); setLoadingEvent(false); setPrivacyType(''); setIsPrivacyDisabled(false); }}>
                <Nav.Link eventKey="link-1">Normal Post</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => { setLoadingNormalPost(false); setLoadingEvent(true); setPrivacyType(''); setIsPrivacyDisabled(false); }}>
                <Nav.Link eventKey="link-2">Event</Nav.Link>
              </Nav.Item>
            </Nav>
            <Card className='mt-3'>
              {loadingNormalPost &&
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Post Type
                        <span className='text-danger'> * </span>
                      </Form.Label>
                      <Form.Select
                        value={postType}
                        onChange={(e) => setPostType(e.target.value)}
                        autoFocus
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="help">Help</option>
                        <option value="others">Others</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide the type of your post.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="What do you want to talk about?"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="file"
                        id='file'
                        name='image'
                        accept='image/gif, image/jpeg, image/png'
                        style={{ display: 'none' }}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    {shareImage && <img src={URL.createObjectURL(shareImage)} style={{ width: '100%', height: 600 }} />}
                    {assetArea === 'media' && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>Video</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter a video link"
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                          />
                        </Form.Group>
                        {videoLink && (
                          <ReactPlayer width={"100%"} url={videoLink} />
                        )}
                      </>
                    )}
                  </Form>
                  <div className='d-flex justify-content-start mt-3'>
                    <div className='menu-item-social'>
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{ cursor: 'pointer', marginRight: 10, color: '#2222e785' }}
                        onClick={() => switchAssetArea('image')}
                      />
                      <label htmlFor="file">Media</label>
                    </div>
                    <div className='menu-item-social' onClick={() => switchAssetArea('media')}>
                      <FontAwesomeIcon
                        icon={faVideo}
                        style={{ cursor: 'pointer', marginRight: 10, color: 'red' }}
                      />
                      Video
                    </div>
                  </div>
                </Card.Body>
              }
              {loadingEvent &&
                <Card.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter the title"
                        autoFocus
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a title.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Start Date
                          <span className='text-danger'> * </span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Select the start date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a start date.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>End Date
                          <span className='text-danger'> * </span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Select the end date"
                          value={lastDate}
                          onChange={(e) => setLastDate(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a end date.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Label>Location
                        <span className='text-danger'> * </span>
                      </Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Group as={Col}>
                          <Form.Control
                            type="text"
                            placeholder="Enter the location"
                            value={eventlocation}
                            onChange={(e) => setEventLocation(e.target.value)}
                            disabled
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a specific location.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" onClick={getCoordinates}>Get Current Location</Button>
                      </Stack>
                      <div>
                        {locationError && <p style={{ color: "red" }}>{locationError}</p>}
                        {loadingEventGeoLocation && <p>Loading.Please wait...</p>}
                      </div>
                    </Row>

                    <Form.Group className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="What do you want to talk about?"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="file"
                        id='file'
                        name='image'
                        accept='image/gif, image/jpeg, image/png'
                        style={{ display: 'none' }}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    {shareImage && <img src={URL.createObjectURL(shareImage)} style={{ width: '100%', height: 600 }} />}
                  </Form>
                  <div className='d-flex justify-content-start mt-3'>
                    <div className='menu-item-social'>
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{ cursor: 'pointer', marginRight: 10, color: '#2222e785' }}
                        onClick={() => switchAssetArea('image')}
                      />
                      <label htmlFor="file">Media</label>
                    </div>
                  </div>
                </Card.Body>
              }
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {loadingNormalPost &&
              <Button variant="primary" onClick={handlePostRequest}>
                Post
              </Button>
            }
            {loadingEvent &&
              <Button variant="primary" onClick={handlePostEventRequest}>
                Post
              </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showLikesModal} onHide={handleCloseLikesModal} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            Likes [{likesData.length}]
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HandleLikesComponent postedLikes={likesData} />
        </Modal.Body>
      </Modal>

      <Modal show={showCommentsModal} onHide={handleCloseCommentsModal} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            Comments [{commentsData.length}]
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HandleLoadMoreCommments postedComment={commentsData} upperlimit={0} lowerlimit={10} />
          <Collapse in={openLoadMoreComments}>
            <HandleLoadMoreCommments postedComment={commentsData} upperlimit={10} lowerlimit={loadMoreCounter} />
          </Collapse>
          {commentsData.length > loadMoreCounter &&
            <Button
              variant="light"
              className='mt-2'
              onClick={() => { setOpenLoadMoreComments(!openLoadMoreComments); setLoadMoreCounter(loadMoreCounter + 10) }}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ cursor: 'pointer', marginRight: 10 }}
              />
              Load more...
            </Button>
          }
        </Modal.Body>
      </Modal>

      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} profileType={currentUser.account_type} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <Container className='mt-3'>
        {spinnerLoading ?
          <div className='text-center'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          :
          <Row>
            <Col sm>
              <Card>
                <Card.Body style={{ height: 60, backgroundColor: '#2222e785' }}></Card.Body>
                {userPersonalData.profile_photo !== null ?
                  <Card.Img variant="top" src={userPersonalData.profile_photo} height={100} style={{ borderRadius: '50%', alignSelf: 'center', width: 100, marginTop: -50 }} />
                  : <Card.Img variant="top" src={BlankProfilePicture} height={100} style={{ borderRadius: '50%', alignSelf: 'center', width: 100, marginTop: -50 }} />
                }
                <Card.Body className='text-center'>
                  {currentUser.account_type === 'institute' ?
                    <Card.Title onClick={handleName}>{userPersonalData.club_name}</Card.Title> :
                    <Card.Title onClick={handleName}>{userPersonalData.first_name} {userPersonalData.last_name}</Card.Title>
                  }
                  {/* {userPersonalData.sport_type !== '' && playerCurrentClub !== '' && sportProfileType !== '' && */}
                  < Card.Text style={{ color: 'GrayText' }}>
                    {/* {userPersonalData.sport_type !== '' && sportProfileType !== '' &&
                      <div>
                        {userPersonalData.sport_type} - {sportProfileType}
                      </div>
                    } */}
                    {userPersonalData.sport_type !== '' && userPersonalData.sport_type !== null ?
                      sportProfileType !== '' &&
                      <div>
                        {userPersonalData.sport_type} - {sportProfileType}
                      </div> :
                      <div>
                        {sportProfileType}
                      </div>
                    }
                  </Card.Text>
                  {/* } */}
                </Card.Body>
                {/* <hr></hr> */}
                <ListGroup className="list-group-flush" style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                  <ListGroup.Item className='listGroupItem' onClick={() => navigate(`/network/connected`)}>
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    />
                    Connections [{connectedNetworkRequest.length}]
                  </ListGroup.Item>
                  <ListGroup.Item className='listGroupItem' onClick={() => navigate(`/network/connect`)}>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    />
                    Invitations [{pendingNetworkRequest.length}]
                  </ListGroup.Item>
                  <ListGroup.Item className='listGroupItem' onClick={() => navigate(`/mypost`)}>
                    <FontAwesomeIcon
                      icon={faPhotoFilm}
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    />
                    My Post
                  </ListGroup.Item>
                </ListGroup>
                {/* <hr></hr> */}
                {/* <Card.Body className='listGroupItem' onClick={() => navigate(`/editprofile`)}>
                  <Card.Text style={{ color: 'GrayText' }}>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ cursor: 'pointer', marginRight: 10 }}
                    />
                    Edit Profile
                  </Card.Text>
                </Card.Body> */}
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Form>
                          <Form.Group controlId="formPost">
                            <Form.Control size="lg" type="text" placeholder="Start a post" onClick={handleShow} />
                          </Form.Group>
                        </Form>
                      </Row>
                      {/* <div className='d-flex justify-content-between mt-3'>
                        <div className='menu-item-social' onClick={handleShow}>
                          <FontAwesomeIcon
                            icon={faImage}
                            style={{ cursor: 'pointer', marginRight: 10, color: '#2222e785' }}
                          />
                          Media
                        </div>
                        <div className='menu-item-social' onClick={handleShowEvent}>
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            style={{ cursor: 'pointer', marginRight: 10, color: 'red' }}
                          />
                          Event
                        </div>
                        <div className='menu-item-social' onClick={handleShow}>
                          <FontAwesomeIcon
                            icon={faNewspaper}
                            style={{ cursor: 'pointer', marginRight: 10, color: 'orange' }}
                          />
                          Article
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <hr></hr>
              {postRequestList}
            </Col>
            <Col sm>
              <Card>
                <Card.Body>
                  <Card.Title>Sports News</Card.Title>
                </Card.Body>
                {news && news.length > 0 &&
                  <>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item className='listGroupItem' onClick={() => navigate("/news", { state: { item: news[0], from: 'home' } })}>
                        <FontAwesomeIcon
                          icon={faCircleDot}
                          size='1x'
                          style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        {news[0].title}
                        <div style={{ color: 'GrayText', marginLeft: 20 }}>
                          {new Date(news[0].start_date).toLocaleDateString()}
                        </div>
                      </ListGroup.Item>
                      {news.length > 1 &&
                        <ListGroup.Item className='listGroupItem' onClick={() => navigate("/news", { state: { item: news[1], from: 'home' } })}>
                          <FontAwesomeIcon
                            icon={faCircleDot}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                          />
                          {news[1].title}
                          <div style={{ color: 'GrayText', marginLeft: 20 }}>
                            {new Date(news[1].start_date).toLocaleDateString()}
                          </div>
                        </ListGroup.Item>
                      }

                      <Collapse in={open}>
                        <div id="example-collapse-text">
                          {news.slice(2).map((item) =>
                            <>
                              <ListGroup.Item className='listGroupItem' onClick={() => navigate("/news", { state: { item: item, from: 'home' } })}>
                                <FontAwesomeIcon
                                  icon={faCircleDot}
                                  style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                {item.title}
                                <div style={{ color: 'GrayText', marginLeft: 20 }}>
                                  {new Date(item.start_date).toLocaleDateString()}
                                </div>
                              </ListGroup.Item>
                            </>
                          )}
                        </div>
                      </Collapse>

                    </ListGroup>
                  </>
                }
                <Card.Body>
                  {news.length > 2 &&
                    <Button
                      onClick={() => setOpen(!open)}
                    >
                      {open ?
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        :
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                      }
                      {
                        open ? 'Show less' : 'Show more'
                      }
                    </Button>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        }
      </Container>
      <ToastContainer />
    </>
  )
}
