import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
// import logo from '../logo.svg';
// import BlankProfilePicture from '../assets/blank-profile-picture.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../context/AuthContext';
import './css/NavBar.css';
// import { Modal, Button, Form, Col, Nav, Card } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Nav from 'react-bootstrap/Nav';
// import Card from 'react-bootstrap/Card';
// import { data } from 'jquery';
// import Select from 'react-select';
// import { SEARCHPLAYER_URL, SEARCHCOACH_URL, SEARCHAGENT_URL, SEARCHINSTITUITION_URL } from '../services/Constants';

export default function Navbar({ loading, setLoading, searchData, setSearchData, flag, profileType }) {

    let {
        user,
        logoutUser,
        getUserList,
        userList,
        sportProfileList,
        userSportProfileInfo
    } = useContext(AuthContext)

    const navigate = useNavigate();

    const handleSearchFilter = (value) => {
        if (flag) {
            const res = userList.filter(x => (x.first_name.toLowerCase().includes(value) && x.id !== user.user_id) || (x.last_name.toLowerCase().includes(value) && x.id !== user.user_id) || (x.is_flag === true ? x.club_name !== null && x.club_name !== '' ? x.club_name.toLowerCase().includes(value) && x.id !== user.user_id : null : null));
            console.log('filtererd userList', res);
            setSearchData(res);
            setLoading(true)
            if (value === "") {
                setSearchData([]);
                setLoading(false)
            }
        }
    }

    const sportProfile = sportProfileList.map((item) => {
        if ((item.profile_type === 'Institution') && (item.is_active))
            return (
                <li className="nav-item">
                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/notifications/pending">
                        <i className="fas fa-bell fa-lg"></i>
                        <span className="small">Notifications</span>
                    </NavLink>
                </li>
            )
    })

    useEffect(() => {
        getUserList();
        // console.log('user list: ', userList);
        userSportProfileInfo();
    }, [])

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container">
                    <div className="d-flex flex-row">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* <a className="navbar-brand" href="#"><i className="fab fa-linkedin fa-2x"></i></a> */}
                        <form className="input-group mx-3" style={{ width: 300 }}>
                            <input type="search" className="form-control" placeholder="Search..." aria-label="Search" onChange={e => handleSearchFilter(e.target.value)} disabled={!flag} />
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                data-bs-ripple-color="dark"
                                style={{ padding: '.45rem 1.5rem .35rem' }}
                                // onClick={() => setShowSearchModal(true)}
                                onClick={() => navigate('/advancefilter')}
                                disabled={!flag}
                            >
                                Advance Search
                            </button>
                        </form>
                    </div>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                    {flag ?
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/">
                                        <i className="fas fa-home fa-lg"></i>
                                        <span className="small">Home</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuYourDayLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-calendar-day fa-lg"></i>
                                        <span className="small">Your day</span>
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuYourDayLink">
                                        <li><NavLink className="dropdown-item" to="/wellness">Your wellness</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/conditioning">Your conditioning</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuRequestLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-envelope fa-lg"></i>
                                        <span className="small">Requests</span>
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuRequestLink">
                                        <li><NavLink className="dropdown-item" to="/opportunaties">Opportunity</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/help">Help</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/endorsements/pending">Endorsement</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/eventsnearme">
                                        <i className="fas fa-location-arrow"></i>
                                        <span className="small">Events near me</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/network/connect">
                                        <i className="fas fa-user-friends fa-lg"></i>
                                        <span className="small">My network</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/messaging">
                                        <i className="fas fa-comment-dots fa-lg"></i>
                                        <span className="small">Messaging</span>
                                    </NavLink>
                                </li>

                                {sportProfile}

                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-user fa-lg"></i>
                                        <span className="small">Profile</span>
                                    </div>
                                    {profileType !== 'institute' ?
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofile">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofile">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul> :
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofileinstituition">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofileinstituition">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul>
                                    }
                                </li>
                            </ul>
                        </div> :
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-user fa-lg"></i>
                                        <span className="small">Profile</span>
                                    </div>
                                    {profileType !== 'institute' ?
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofile">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofile">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul> :
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofileinstituition">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofileinstituition">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul>
                                    }
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </nav>

        </>
    )
}